.homeheroimage {
  width: 75px;
  height: 75px;
  user-select: none;
  border-radius: 100px;
  @media (min-width: 400px) {
    width: 90px;
    height: 90px;
  }
  @media (min-width: 1000px) {
    width: 175px;
    height: 175px;
  }
}

.homeperiodimage {
  width: 75px;
  height: 73.8px;
  user-select: none;
  border-radius: 100px;
  @media (min-width: 400px) {
    width: 108px;
    height: 106.2px;
  }
  @media (min-width: 1000px) {
    width: 150px;
    height: 147px;
  }
}
/* the baseswap webp file is 321x316. this makes the multiplier to preserve aspect ratio 
0.9844, meaning what you set the width to * the muliplier = height.  
multiplier = 316/321
*/

.dashboardimage {
  width: 75px;
  height: 75px;
  user-select: none;
  border-radius: 100px;

  @media (min-width: 1000px) {
    width: 140px;
    height: 125px;
  }
}
