.bodytext {
  font-size: 12px !important;
  font-weight: 400 !important;
  @media (min-width: 361px) {
    font-size: 14px !important;
  }
  @media (min-width: 1000px) {
    font-size: 14px !important;
  }
}

.bodytext2 {
  font-size: 12px !important;
  font-weight: 400 !important;
  @media (min-width: 361px) {
    font-size: 14px !important;
  }
  @media (min-width: 1000px) {
    font-size: 16px !important;
  }
}

.text1 {
  font-size: 22px !important;
  @media (min-width: 361px) {
    font-size: 25px !important;
  }
  @media (min-width: 1000px) {
    font-size: 32px !important;
  }
}

.text2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  @media (min-width: 361px) {
    font-size: 20px !important;
  }
  @media (min-width: 1000px) {
    font-size: 28px !important;
  }
}

.text3 {
  font-size: 18px !important;
  @media (min-width: 361px) {
    font-size: 18px !important;
  }
  @media (min-width: 1000px) {
    font-size: 22px !important;
  }
}

.text4 {
  font-size: 18px !important;
  @media (min-width: 361px) {
    font-size: 18px !important;
  }
  @media (min-width: 1000px) {
    font-size: 20px !important;
  }
}

.text5 {
  font-size: 14px !important;
  font-weight: 400 !important;
  @media (min-width: 361px) {
    font-size: 14px !important;
  }
  @media (min-width: 1000px) {
    font-size: 18px !important;
  }
}

.text6 {
  font-size: 12px !important;

  @media (min-width: 361px) {
    font-size: 12px !important;
  }
  @media (min-width: 1000px) {
    font-size: 14px !important;
  }
}

.text6 {
  font-size: 16px !important;
  @media (min-width: 361px) {
    font-size: 16px !important;
  }
  @media (min-width: 500px) {
    font-size: 25px !important;
  }
  @media (min-width: 1000px) {
    font-size: 32px !important;
  }
}


.tabtext {
  font-size: 14px !important;
  @media (min-width: 1000px) {
    font-size: 14px !important;
  }
}

.poolsportfolio {
  font-size: 22px !important;
  @media (min-width: 1000px) {
    font-size: 25px !important;
  }
}
