.SelectTrigger {
  display: inline-flex;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-width: 1px;
  border-color: var(--chakra-colors-text-800);
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1;
  background: var(--chakra-colors-insetComponent);
  box-shadow: var(--chakra-shadows-outsetComponentShadow);
  color: var(--chakra-colors-text-200);
}
.SelectTrigger:hover {
  box-shadow: var(--chakra-shadows-insetComponentShadow);
}

.SelectTrigger:focus {
  outline: none;
}

.SelectTrigger[data-placeholder] {
  color: var(--chakra-colors-gray-400);
  border-color: transparent;
}
.SelectIcon {
  color: var(--chakra-colors-gray-400);
}

.SelectContent {
  overflow: hidden;

  background: var(--chakra-colors-insetComponent) !important;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid;
  border-color: #bbb !important;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 12px;

  color: var(--chakra-colors-gray-400);
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 35px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.SelectItem:hover {
  background: var(--chakra-colors-insetComponent) !important;
  box-shadow: var(--chakra-shadows-insetComponentShadow) !important;
  color: var(--chakra-colors-gray-100) !important;
}

.SelectItem[data-disabled] {
  color: rgb(236, 106, 36);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: #222;
  color: #fff;
  border-radius: 12px;
}

.SelectLabel {
  padding: 0 20px;
  font-size: 14px;
  line-height: 35px;
  color: var(--chakra-colors-gray-400);
}

.SelectSeparator {
  height: 1px;
  background-color: rgb(21, 3, 101);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: #911;
  cursor: default;
}
