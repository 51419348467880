.fade-in-opacity {
  --start-opacity: 1;
}

.fade-in-scale {
  --start-scale: 0.98;
}
@media only screen and (width >= 1000px) {
  .fade-in-opacity {
    --start-opacity: 0;
  }

  .fade-in-scale {
    --start-scale: 0.98;
  }
}

.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
  transition: opacity 1s var(--ease-out-cubic), transform 1s var(--ease-out-cubic);
  transform-origin: top center;
}

.hidden {
  opacity: var(--start-opacity);
  transform: translateY(5px) scale(var(--start-scale));
}

@keyframes colorShiftShadow {
  0%,
  100% {
    box-shadow: 0 0 8px -4px #60fe00;
  }
  50% {
    box-shadow: 0 0 12px -4px #dffe00;
  }
  25% {
    box-shadow: 0 0 8px -4px #dffe00;
  }
}

.moveshadow {
  width: 100px;
  height: 100px;
  animation: colorShiftShadow 5s infinite;
}

@keyframes fadeInDown {
  0% {
    transform: translateY(10%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInDown-animation {
  animation: fadeInDown 1s ease-in-out;
}
