.datepicker input {
  background-color: transparent;
}

.react-datetime-picker {
  border: 1px solid;
  border-color: transparent;
  width: 100%;
  min-height: 48px;
  padding-left: 12px;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  padding-right: 12px;
  padding-inline-end: var(--input-padding);
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-duration: var(--chakra-transition-duration-normal);
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  border-radius: var(--input-border-radius);
}

.react-datetime-picker__calendar {
  background: #000;
  padding: 12px;
  min-width: 400px;
  border: 1px solid var(--chakra-colors-border);
  border-radius: var(--input-border-radius);
}
.react-datetime-picker__wrapper {
  border: 0px !important;
}

.react-datetime-picker__button__icon {
  stroke: #fff;
}

.react-datetime-picker__clear-button {
  visibility: hidden;
}

.react-calendar {
  border: 0px !important;
}

.react-calendar__navigation {
  text-align: center;
}

.react-calendar__tile {
  color: #fff;
  padding: 4px;
}

.react-calendar__tile:hover {
  opacity: 0.6;
}

.react-calendar__month-view__days {
  margin-top: 12px;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 16px;
  margin-top: 12px;
}

.react-datetime-picker__clock {
  border: 1px solid var(--chakra-colors-border) !important;
  box-shadow: 0px 0px 10px 0px var(--chakra-colors-border) !important;
  background-color: var(--chakra-colors-appTheme-darkblue-100) !important;
  border-radius: var(--input-border-radius) !important;
}

.react-datetime-picker__clock--open {
  background-color: var(--chakra-colors-appTheme-darkblue-100);
}
