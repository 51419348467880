@import './modules/datetime.module.css';
@import './modules/text.module.css';
@import './modules/select.module.css';
@import './modules/gradients.module.css';
@import './modules/dashboard.module.css';
@import './modules/homehero.module.css';
@import './modules/backgrounds.module.css';
@import './modules/animation.module.css';

:root {
  --ease-out-cubic: cubic-bezier(0.22, 0.61, 0.36, 1);
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100%;
}

#__next > div {
  height: 100%;
}

.rc-pagination {
  list-style: none;
  display: flex;
}
.modalContent {
  width: 100% !important;
}

.icon-wrapper svg {
  transition: stroke 0.3s;
}

.icon-wrapper:hover svg {
  stroke: var(--chakra-colors-primary);
}

.input-container {
  position: relative;
  display: inline-block;
}

#token-amount-input {
  padding-right: 20px; /* Adjust based on the width of the percent sign */
  box-sizing: border-box;
}

.percent-sign {
  position: absolute;
  right: 10px; /* Adjust based on your design */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* KYBER WIDGET */
/* hiding dex type since all say swapmode */
/* order matters */
.dex-type img,
.dex-type span:not(.divide) {
  display: none !important;
}

.dex-type .divide {
  display: none !important;
}

.ks-lw {
  max-width: 700px !important;
}

.liquidityWidgetModalContainer .chakra-modal__body {
  background: transparent !important;
}

.ks-lw-style .overflow-visible {
  overflow: hidden !important;
}

.ks-lw-style .bg-layer2 {
  background: var(--chakra-colors-outsetComponent) !important;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 18px;
  --modal-shadow: var(--chakra-shadows-lg);
  background: var(--chakra-colors-outsetComponent);
  box-shadow: 0 0 48px var(--chakra-colors-highlight);
  border-width: 1px;
  border-color: var(--chakra-colors-tableborder);
  padding-inline: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.ks-lw-modal-content {
  background: var(--chakra-colors-outsetComponent) !important;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 18px;
  --modal-shadow: var(--chakra-shadows-lg);
  background: var(--chakra-colors-outsetComponent);
  box-shadow: 0 0 48px var(--chakra-colors-highlight);
  border-width: 1px;
  border-color: var(--chakra-colors-border);
  padding-inline: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.ks-lw-style .ks-lw-setting {
  border-width: 1px !important;
  border-color: var(--chakra-colors-border);
}

.ks-lw-modal-content .bg-layer2 {
  box-shadow: 0 0 48px var(--chakra-colors-highlight) !important;
}

.ks-lw-content .left .liquidity-to-add .input-token {
  box-shadow: none !important;
  margin-top: 10px;
  border: 1px solid var(--ks-lw-stroke);
  background: var(--ks-lw-layer2) !important;
  border-radius: var(--ks-lw-borderRadius);
  padding: 12px;
  filter: brightness(84%);
}

.ks-lw-style .bg-\[\#0f0f0f\] {
  background: var(--chakra-colors-swaptokeninput) !important;
}

.ks-lw-modal-content .text-xl {
  color: var(--chakra-colors-text-100) !important;
}

.ks-lw .ks-lw-content .right .zap-route .detail-row {
  font-size: 14px !important;
}

.ks-lw-content .right .zap-route .title {
  font-size: 16px !important;
}

.ks-lw .ks-lw-content .right .zap-route .detail-row {
  font-size: 14px !important;
}

.ks-lw-content .right .zap-route .subTitle {
  font-size: 14px !important;
}

.ks-lw-content .left .price-input .input-wrapper {
  font-size: 14px !important;
}

.ks-lw-content .left .price-input .input-wrapper input {
  font-size: 18px !important;
}