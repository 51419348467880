/* 6 components  */
/* // const breakpoints = {
  //   base: '0px',
  //   xs: '361px',
  //   sm: '450px',
  //   md: '768px',
  //   lg: '1000px',
  //   xl: '1200px',
  //   xxl: '1500px',
  // }; */

.toprowflex:hover {
  background: #000;
  cursor: pointer;
  border-color: #fff;
  transform: scale(1.02);
  transition: transform 0.1s ease;
}

/* #3af, */

.dashboardcube {
  border: 1px;
  border-color: #333;
  border-radius: 18px;
  max-width: 100vw;
  background: rgba(255, 255, 255, 0.01);
  padding: 1px;
  backdrop-filter: blur(12px);
}

.dashboardcube:hover {
  border-color: #bbb;
}

.dashboardtemplate {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  border: 1px solid;
  display: flex;
  border-color: #333;
  border-radius: 18px;
  padding: 1px;
}

.templateplain {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  flex-wrap: wrap;
  border: 1px solid;
  display: flex;
  border-color: #474646;
  border-radius: 18px;
  padding: 1px;
}
