

.graycircle {
  background: radial-gradient(circle at 20% 80%, rgba(41, 41, 41, 0.8), transparent 40%),
    linear-gradient(to right, #2b2b2b, #323232, #2d2d2d);
  background-position: center, top left;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  position: relative;
}

.texturecircle {
  background: repeating-radial-gradient(
      circle at 50% 50%,
      transparent 0,
      transparent 5px,
      rgba(60, 60, 60, 0.1) 5px,
      rgba(60, 60, 60, 0.1) 10px
    ),
    linear-gradient(135deg, #292929, #353535);
  background-position: center;
  background-repeat: repeat, no-repeat;
  background-blend-mode: multiply;
  position: relative;
}

.texturevertical {
  background: radial-gradient(circle at 10% 90%, rgba(0, 183, 255, 0.5), transparent 30%),
    repeating-linear-gradient(
      90deg,
      rgba(62, 62, 62, 0.1) 0px,
      rgba(62, 62, 62, 0.1) 1px,
      transparent 1px,
      transparent 10px
    ),
    linear-gradient(180deg, #1c1c1c, #2a2a2a);
  background-position: center, center, top left;
  background-repeat: no-repeat, repeat, no-repeat;
  background-blend-mode: overlay;
  position: relative;
}
